<template>
  <div class=”gallery” data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>Gallery</h2>
              <ul>
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li><span>Gallery</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container portfolio-wrap">
      <div class="row portfolio-container">
        <div class="gooey" v-if="show_gallery.length === 0" style="height: 500px">
          <img src="../assets/images/Infinity-loader.gif" alt="">
        </div>
        <div class=" portfolio-item" v-else v-for="gallery in show_gallery" :key="gallery.id">
          <div class="portfolio-cont">
              <div class="img-wrapper">
              <img :src="gallery.image" @click.prevent="zoomImage(gallery.image)" @error="setAltImg" alt="">
            </div>
            <h3 class="entry-title">{{gallery.title}}</h3>
            <p>{{gallery.description}}</p>
          </div>
        </div>
      </div>
      <div class="row" v-if="load_more">
        <div class="col-12 d-flex justify-content-center mt-72">
          <button href="#" class="btn gradient-bg load-more-btn" @click.prevent="loadMore()" :disabled="load_more_disable">Load More</button>
        </div>
      </div>
    </div>
    <modal name="gallery" :adaptive="true" width="75%" height="75%">
      <div class="img-modal">
        <img :src="modalImg" width="100%">
      </div>
      <a href="#" @click.prevent="closeModal()" class="btn-close" title="Close">X</a>
    </modal>
  </div>
</template>

<script>
    import api from '../services/api.js';

    export default {
        name: "gallery",
        data() {
            return {
                offset: 0,
                limit: 6,
                show_gallery: [],
                load_more: true,
                load_more_disable: false,
                modalImg: ''
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            this.getGallery(this.offset, this.limit)
        },
        methods: {
            setAltImg(event) {
              event.target.src = require("@/assets/images/default-image.jpg")
            },
            loadMore() {
                this.offset = this.offset + this.limit;
                this.getGallery(this.offset, this.limit)
            },
            async getGallery(offset, limit) {
                this.load_more_disable = true;
                let more               = await api.gallery(offset, limit);
                more                   = more.data;
                if (more.length < this.limit) {
                    this.load_more = false
                }
                more.forEach((value) => {
                    this.show_gallery.push(value)
                });
                for (const value of more) {
                    if (value.image !== null) {
                        let img = await api.getFiles(value.image);
                        if (img !== null) {
                            value.image = img.data['data']['full_url'];
                        }
                    }
                }
                this.load_more_disable = false
            },
            zoomImage(img) {
                this.$modal.show('gallery');
                this.modalImg = img;
            },
            closeModal() {
              this.$modal.hide('gallery');
            }
        },
    }
</script>

<style scoped>
  .portfolio-wrap {
    padding: 96px 0;
  }
  .portfolio-wrap .portfolio-item {
    margin-bottom: 30px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 33%;
  }
  .img-wrapper {
    width: 100%;
    height: 300px;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }
  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  .portfolio-cont {
    padding: 10px;
  }
  .portfolio-cont p{
    min-height: 25px;
  }
  .load-more-btn {
    border-radius: 5px;
    background-color: #a14ec6;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  .d-flex.justify-content-center {
    display: flex;
    justify-content: center;
  }
  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vm--container {
    z-index: 99999999;
  }
  .img-modal {
    height: 100%;
    padding: 0;
    display: inline-block;
  }
  .img-modal img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .portfolio-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 0 15px;
  }
  .entry-title {
    word-break: break-all;
  }
  @media (max-width: 992px) {
    .portfolio-wrap .portfolio-item {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    .portfolio-wrap .portfolio-item {
      width: 100%;
    }
  }
  .btn-close {
    position: absolute;
    display: block;
    top: 0px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    z-index: 99999;
    
  }
</style>
